var site = site || {};

site.client = site.client || {};

(function ($) {
  $('.js-product-grid').on('products:inventory_sorted', function () {
    var $grid = $(this);

    if (!!$grid.data('isSlider')) {
      return;
    }
    if ($grid.data('isotope')) {
      $grid.isotope('destroy');
    }
    var $tout = $('.js-grid--mpp__item-tout', $grid);
    var $product = $('.grid--mpp__item:not(.js-grid--mpp__item-tout)', $grid);
    var gridItemCount = $product.length;
    var toutPosition;

    $tout.each(function (index) {
      // sortInitialPosition gets incremented on set to properly work with isotope
      // need to reset to it's original value here
      toutPosition = $(this).data('sortInitialPosition') - 1;
      if (toutPosition < gridItemCount) {
        $(this)
          .detach()
          .insertBefore($product[toutPosition - index]);
      } else {
        $(this).detach().insertAfter($product.last());
      }
    });
    Drupal.behaviors.mppProductLayoutCustomOverrideV1.setInitialPosition($grid);
    $grid.trigger('reInitGrid');
  });

  Drupal.behaviors.mppProductLayoutCustomOverrideV1 = {
    isMobile: false,
    unisonDependent: true,
    $grids: $(),
    nodes: {},
    selector: {
      container: '.js-mpp-custom-override-layout',
      grid: '.js-product-grid',
      item: '.js-product-grid-item',
      defaultFilter: '.js-filterable-products__grid-item-prefilter',
      gutter: '.js-grid--mpp__gutter-sizer',
      tout: '.js-filterable-products__grid-item-tout',
      wideTout: '.rectangle_wide', // css class comes from CMS ( no js prefix )
      tallTout: '.rectangle_tall', // css class comes from CMS ( no js prefix )
      filtersModelsContainer: '.js-filters_container',
      filtersContainer: 'js-mpp-filters',
      modelImagesContainer: '.js-mpp-model-images__container',
      modelImageOption: '.js-mpp-model-images__option',
      requireMasonry: '.rectangle_tall'
    },
    cssClass: {
      tout: 'js-filterable-products__grid-item-tout'
    },
    settings: {
      layout: 'masonry'
    },
    urlString: window.location.href,
    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      // if Unison is not ready on attach, it triggers change on initialization
      if (!!Unison.fetch.now()) {
        self.isMobile = self.checkIfMobile();
        self.unisonReady = true;
      }
      Unison.on('change', function () {
        if (!self.attached && self.unisonDependent) {
          // re attach on unisonReady, the initial attach was return
          self.attach(context);
        }
      });
      if (!self.unisonReady && self.unisonDependent) {
        return;
      }
      self.attached = true;
      self.$grids = $(self.selector.grid, context);
      self.$grids.each(function () {
        var $this = $(this);
        var isToutContent = $this.closest(self.selector.tout).length > 0;

        if (isToutContent) {
          return true;
        }
        self.setInitialPosition($(this));
        self.setup($(this));
      });
    },

    checkIfMobile: function () {
      var currentBpWidth = parseInt(Unison.fetch.now().width, 10);
      var bPlargeUp = parseInt(Unison.fetch.all()['usn-medium'], 10);

      return currentBpWidth <= bPlargeUp;
    },

    setSettings: function ($that) {
      var self = this;
      $that.settings = $.extend({}, self.settings);
      $that.settings.layout = self.getLayout($that);
      $that.settings.direction = $('html').attr('dir') || 'ltr';
      $that.settings.breakpoints = $that.data('breakpoints') || {};
      $that.settings.sliderConfig = $that.data('sliderConfig') || {};

      $that.settings.sliderConfig.dots = $that.settings.sliderConfig.dots > 0 ? true : false;
      $that.settings.sliderConfig.arrows = $that.settings.sliderConfig.arrows > 0 ? true : false;
      $that.settings.sliderConfig.infinite =
        $that.settings.sliderConfig.infinite > 0 ? true : false;

      if (
        !!$that.settings.sliderConfig &&
        !!$that.settings.sliderConfig.peeking &&
        !!$that.data('isSlider')
      ) {
        self.setPeaking($that);
      }
    },

    setPeaking: function ($that) {
      $that.settings.sliderConfig.peekingPercent = {};
      Object.keys($that.settings.breakpoints).forEach(function (key) {
        $that.settings.breakpoints[key] = parseInt($that.settings.breakpoints[key]);
        if ($that.settings.sliderConfig.peeking[key].indexOf('.') === 0) {
          $that.settings.sliderConfig.peekingPercent[key] =
            ((100 / $that.settings.breakpoints[key]) *
              parseInt($that.settings.sliderConfig.peeking[key].replace('.', ''))) /
            100;
        }
      });
    },

    getLayout: function ($that) {
      var self = this;
      var requireMasonry = $that.find(self.selector.requireMasonry).length > 0;
      return requireMasonry ? 'masonry' : 'mixItUp';
    },

    setup: function ($that) {
      var self = this;

      Unison.on('change', function () {
        self.isMobile = self.checkIfMobile();
      });
      self.getDom($that);
      self.setSettings($that);
      Unison.on('change', function () {
        self.swapLayout($that);
      });
      self.setEvents($that);
      $that.addClass('grid-layout--' + $that.settings.layout);
      if (!$that.data('isSlider')) {
        // We don't need to alter the touts when grid is a slider.
        self.setToutsLayout($that);
      }
      $that.addClass('first-load');
      self.initGrid($that);
      if (!!$that.data('isSlider')) {
        setTimeout(function () {
          // allow items layout to finish
          self.initSlider($that);
        }, 100);
      }
    },

    swapLayout: function ($that) {
      var self = this;

      if (!$that.data('isSlider')) {
        // We don't need to alter the touts when grid is a slider.
        self.resetToutLayout($that);
        self.setToutsLayout($that);
      }
      self.initGrid($that);
    },

    initGrid: function ($that) {
      var self = this;

      if (!!$that.data('isSlider')) {
        return;
      }

      if (self.isMobile || $that.settings.layout === 'mixItUp') {
        // isotope seems to have some issues on mobile, and it's useless since there
        // is no custom grid item ( tout / featured )
        self.loadMixItUpGrid($that);

        return;
      }
      self.loadIsotopeGrid($that);
    },

    loadMixItUpGrid: function ($that) {
      var self = this;
      var preFilterString = 'all';
      if ($that.data('isotope')) {
        $that.isotope('destroy');
      }
      if ($that.mixItUp('isLoaded')) {
        $that.mixItUp('destroy');
      }
      if ($that.data('preFilterString') && $that.data('preFilterString') !== self.defaultFilter) {
        preFilterString = $that.data('preFilterString');
      }
      var pageloadAnimation = preFilterString !== 'all';

      $that.nodes.$items.hide();
      var mixItUpArgs = {
        animation: {
          enable: pageloadAnimation
        },
        selectors: {
          target: self.selector.item,
          sort: '.js-mpp-sort-option'
        },
        layout: {
          display: 'flex'
        },
        load: {
          filter: preFilterString
        }
      };

      if (!pageloadAnimation) {
        $that.addClass('no-animation');
      }

      $that.mixItUp(mixItUpArgs);
      setTimeout(function () {
        $that.removeClass('first-load');
      });
    },

    getPrefilterString: function ($that) {
      var $currentFilter = '';
      var filterValues = self.urlString.split('?filter=').pop().split(',');
    },

    deepLinkFilter: function ($that) {
      var self = this;
      if (self.urlString.includes('?filter=')) {
        var $currentFilter = '';
        var filterValues = self.urlString.split('?filter=').pop().split(',');

        filterValues.forEach(function (val) {
          $currentFilter = $that.nodes.$filterBtns.filter(
            '[data-filter=".filterable-product-grid__item--filter-' + val + '"]'
          );
          if ($currentFilter.length > 0) {
            $currentFilter.addClass('active');
            $currentFilter.each(function () {
              var $this = $(this);
              self.toggleFilterSection($this, $that);
            });
          }
        });
        self.updateAvailableFilters($that);
        // setTimeout(function () {
        //   self.filter($that);
        // }, 10);
      }
    },

    loadIsotopeGrid: function ($that) {
      var self = this;

      if ($that.hasClass('mixitup-initialized')) {
        $that.mixItUp('destroy');
        $that.removeClass('mixitup-initialized');
      }
      $that.isotope({
        // set itemSelector so .grid-sizer is not used in layout
        itemSelector: self.selector.item,
        percentPosition: true,
        layoutMode: self.settings.layout,
        resizesContainer: true,
        masonry: {
          gutter: self.selector.gutter
        },
        getSortData: {
          'data-sort-price': function (item) {
            return parseInt($(item).data('sortPrice') * 100);
          },
          'data-sort-new': function (item) {
            return parseInt($(item).data('sortNew') * 100 - $(item).data('sortInitialPosition'));
          },
          'data-sort-bestseller': function (item) {
            return parseInt(
              $(item).data('sortBestseller') * 100 - $(item).data('sortInitialPosition')
            );
          },
          'data-sort-rating': '[data-sort-rating] parseInt',
          'data-sort-initial-position': '[data-sort-initial-position] parseInt'
        }
      });
    },
    getDom: function ($that) {
      var self = this;

      $that.nodes = {};
      $that.nodes.$container = $that.closest(self.selector.container);
      $that.nodes.$items = $(self.selector.item, $that);
      $that.nodes.$products = $that.nodes.$items.filter(':not(' + self.selector.tout + ')');
      $that.nodes.$touts = $(self.selector.tout, $that);
      $that.nodes.$detachedTouts = $();
      $that.nodes.$filtersAndModels = $(
        self.selector.filtersModelsContainer,
        $that.nodes.$container
      );
      $that.nodes.$filters = $(self.selector.filtersContainer, $that.nodes.$container);
      $that.nodes.$modelImagesContainer = $(
        self.selector.modelImagesContainer,
        $that.nodes.$container
      );
      $that.nodes.$modelImagesOption = $(
        self.selector.modelImageOption,
        $that.nodes.$modelImagesContainer
      );
    },

    setEvents: function ($that) {
      var self = this;

      $that.on('mpp-sortBy', function (e, sortBy) {
        if ($that.hasClass('mixitup-initialized')) {
          sortBy = sortBy.replace('data-', '').toLowerCase();
          $that.nodes.$detachedTouts = $that.nodes.$touts.filter(':visible').detach();
          $that.mixItUp('sort', sortBy);
        }
        if ($that.data('isotope')) {
          sortBy = sortBy.split(':');
          var sortByValue = sortBy[0];
          var sortAscending = sortBy[1] ? sortBy[1] === 'ASC' : false;

          $that.isotope({
            sortBy: sortByValue,
            sortAscending: sortAscending
          });
          self.positionTouts($that);
        }
        // self.positionTouts($that);
      });
      $that.on('reInitGrid', function () {
        self.initGrid($that);
      });
      $that.on('mixStart', function (e, state) {
        if (state.activeSort !== 'default:asc' && $that.nodes.$detachedTouts.length < 1) {
          $that.nodes.$detachedTouts = $that.nodes.$touts.filter(':visible').detach();
        }
      });
      $that.on('mixEnd', function (e, state) {
        $that.addClass('mixitup-initialized');
        if (
          state.activeSort !== 'default:asc' &&
          state.activeFilter === self.selector.item &&
          $that.nodes.$detachedTouts.length < 1
        ) {
          $that.nodes.$detachedTouts = $that.nodes.$touts.filter(':visible').detach();
        }
        self.positionTouts($that);
        $that.trigger('mpp-show-product-count');
        // we reenable the animation here, get's turned off for inventory status reinit
        if ($that.hasClass('no-animation')) {
          $that.mixItUp('setOptions', {
            animation: {
              enable: true
            }
          });
          $that.removeClass('no-animation');
        }
      });
      $that.on('arrangeComplete', function () {
        $that.fadeTo(300, 1);
        $that.trigger('mpp-show-product-count');
        if ($that.nodes.$touts.length > 0) {
          self.setToutsLayout($that);
        }
        if (Drupal.settings.globals_variables.cr21) {
          setTimeout(function () {
            self.placeRowDividers($that);
          }, 0);
        }
      });
      $that.on('stateChanged', function () {
        if ($that.data('isotope')) {
          $that.isotope('layout');
        }
      });
      $that.nodes.$modelImagesOption.each(function () {
        var $this = $(this);
        if (Drupal.settings.globals_variables.cr21) {
          self.setEvenWidths($this, $that);
        }
        $this
          .once()
          .on('click', function () {
            if ($this.hasClass('selected')) {
              return;
            }
            self.toggleImages($this, $that);
          })
          .keyup(function (event) {
            if ($this.hasClass('selected')) {
              return;
            }
            if (event.keyCode === 13) {
              self.toggleImages($this, $that);
            }
          });
      });
      $that.nodes.$items.on('pr-data:updated', function () {
        if ($that.data('isotope')) {
          $that.isotope('updateSortData');
        }
      });
    },

    getBreakpoint: function () {
      var width = parseInt(Unison.fetch.now().width, 10);
      if (width <= parseInt(Unison.fetch.all()['usn-medium'], 10)) {
        return 'mobile';
      }
      if (width <= parseInt(Unison.fetch.all()['usn-xxx-large'], 10)) {
        return 'desktop';
      }
      if (width <= parseInt(Unison.fetch.all()['usn-xwide'], 10) > 0) {
        return 'large';
      }
      return 'extra_large';
    },

    initSlider: function ($that) {
      var self = this;
      var hasPeeking =
        !!$that.settings.sliderConfig && !!$that.settings.sliderConfig.peekingPercent;
      var sliderSettings = {
        slidesToShow: $that.settings.breakpoints.extra_large || 3,
        slidesToScroll: 1,
        infinite:
          hasPeeking && $that.settings.sliderConfig.peekingPercent.extra_large > 1
            ? true
            : $that.settings.sliderConfig.infinite,
        dots: false,
        arrows: $that.settings.sliderConfig.arrows,
        slide: '.js-product-grid-item',
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: $that.settings.breakpoints.mobile || 1,
              arrows: false,
              dots: $that.settings.sliderConfig.dots,
              infinite:
                hasPeeking && $that.settings.sliderConfig.peekingPercent.mobile > 0
                  ? true
                  : $that.settings.sliderConfig.infinite
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: $that.settings.breakpoints.desktop || 3,
              infinite:
                hasPeeking && $that.settings.sliderConfig.peekingPercent.desktop > 0
                  ? true
                  : $that.settings.sliderConfig.infinite
            }
          }
        ],
        onInit: function () {
          self.togglePeekingClasses($that);
          $that.trigger('slider-initialised');
        },
        onAfterChange: function () {
          $that.trigger('slider-changed--after');
        }
      };
      $that.slick(sliderSettings);
    },

    togglePeekingClasses: function ($that) {
      var self = this;
      if ($that.settings.sliderConfig.length < 1 || !$that.settings.sliderConfig.peekingPercent) {
        return;
      }
      var direction = $that.settings.direction === 'ltr' ? 'right' : 'left';
      var breakpoint = self.getBreakpoint();
      var hasSlides = $that.settings.breakpoints[breakpoint] < $that.nodes.$items.length;
      var percent = $that.settings.sliderConfig.peekingPercent[breakpoint] || 0;
      if (percent > 0 && hasSlides) {
        percent = self.closestNumber(percent, 5) || 5;
        $that.find('.slick-list').addClass('padding-' + direction + '-' + percent);
        $that.trigger('slider-peeking-changed');
      }
    },

    // Find closest number to n divisible by m
    closestNumber: function (n, m) {
      let q = parseInt(n / m);
      let n1 = m * q;
      let n2 = n * m > 0 ? m * (q + 1) : m * (q - 1);
      if (Math.abs(n - n1) < Math.abs(n - n2)) {
        return n1;
      } else {
        return n2;
      }
    },

    resetToutLayout: function ($that) {
      $that.nodes.$touts.removeClass('tout-layout-processed');
    },

    setToutsLayout: function ($that) {
      var self = this;
      var $unprocessedTallTouts = $that.nodes.$touts.filter(
        self.selector.tallTout + ':not(.tout-layout-processed)'
      );
      var $defaultItem = $that.nodes.$products.filter(':visible').first();
      var defaultHeight = $defaultItem.outerHeight();
      var defaultMargin = $defaultItem.outerHeight(true) - defaultHeight;
      var tallHeightMultiplier = self.isMobile ? 1 : 2;

      if ($unprocessedTallTouts.length > 0) {
        // redo height based on standard item
        $unprocessedTallTouts.each(function () {
          var $tout = $(this).addClass('tout-layout-processed');
          var toutHeight = defaultHeight * tallHeightMultiplier + defaultMargin;
          if ($tout.height() !== $defaultItem.height()) {
            $tout.css({ height: toutHeight + 'px' });
          }
        });
      }
    },

    toggleImages: function ($this, $that) {
      $that.nodes.$modelImagesOption.removeClass('selected');
      $this.addClass('selected');
      $that.trigger('mpp-toggle-image', $this.data('modelOption').toLowerCase());
    },

    setEvenWidths: function ($this, $that) {
      var self = this;
      var $options = $this
        .closest($that.nodes.$modelImagesContainer)
        .find($that.nodes.$modelImagesOption);
      var widths = self.getModelOptionsWidths($options);
      var maxWidth = Math.max.apply(Math, widths);

      for (var i = 0; i < $options.length; i++) {
        $($options[i]).width(maxWidth);
      }
    },

    getModelOptionsWidths: function ($options) {
      var widths = [];
      for (var i = 0; i < $options.length; i++) {
        widths.push($($options[i]).width());
      }
      return widths;
    },

    moveinArray: function (array, from, to) {
      var target = array[from];
      var increment = to < from ? -1 : 1;

      for (var k = from; k !== to; k += increment) {
        array[k] = array[k + increment];
      }
      array[to] = target;

      return array;
    },

    updateFilteredPositionData: function (filteredItems) {
      filteredItems.forEach(function (filteredItem, index) {
        if (!!filteredItem) {
          $(filteredItem.element).data('filteredPosition', index);
        }
      });

      return filteredItems;
    },

    updatePosition: function (filteredItems, $cachedTout) {
      var self = this;

      self.updateFilteredPositionData(filteredItems);
      var initialPositionIndex = parseInt($cachedTout.data('sortInitialPosition')) - 1;

      if (
        !isNaN(initialPositionIndex) &&
        typeof $cachedTout.data('sortInitialPosition') !== 'undefined'
      ) {
        filteredItems = self.moveinArray(
          filteredItems,
          $cachedTout.data('filteredPosition'),
          initialPositionIndex
        );
      }

      return filteredItems;
    },

    positionTouts: function ($that) {
      var self = this;

      if ($that.data('isotope')) {
        self.positionIsotopeTouts($that);
      }
      if ($that.hasClass('mixitup-initialized')) {
        self.positionMixItUpTouts($that);
      }
    },

    positionMixItUpTouts: function ($that) {
      var self = this;
      var $detachedTouts = $that.nodes.$detachedTouts;
      var $visibleItems = $();

      if ($detachedTouts.length > 0) {
        $detachedTouts.each(function () {
          // we need to parse in loop to get the right position of visible items
          // between sorting's
          $visibleItems = $that.find(self.selector.item).filter(':visible');
          var $tout = $(this);
          var initialPositionIndex = parseInt($tout.data('sortInitialPosition')) - 1;
          var $item = $visibleItems.eq(initialPositionIndex);

          if (
            (!isNaN(initialPositionIndex) &&
              typeof $tout.data('sortInitialPosition') === 'undefined') ||
            $item.data('sortInitialPosition') === $tout.data('sortInitialPosition')
          ) {
            // if the tout is in the correct position, we move on
            return;
          }
          $tout.insertBefore($item);
        });
      }
      $that.nodes.$detachedTouts = $();
    },

    placeRowDividers: function ($that) {
      if ($that.data('isotope')) {
        $that.nodes.$items.removeClass('is-row-divider');
        $that.data('isotope').filteredItems.forEach(function (item, index) {
          if (index % 3 === 0) {
            $(item.element).addClass('is-row-divider');
          }
        });
      }
    },

    positionIsotopeTouts: function ($that) {
      var self = this;
      if ($that.nodes.$touts.filter(':visible').length < 1) {
        return;
      }
      var filteredItems = $that.data('isotope').filteredItems;

      // need to loop on cached touts to move them in the correct order
      $that.nodes.$touts.each(function () {
        var $cachedTout = $(this);

        filteredItems = self.updatePosition(filteredItems, $cachedTout);
      });
      // we need to parse both ways to get the final order right
      $.each(Object.keys($that.nodes.$touts).reverse(), function (i, key) {
        var $cachedTout = $($that.nodes.$touts[key]);

        filteredItems = self.updatePosition(filteredItems, $cachedTout);
      });

      $that.data('isotope').filteredItems = filteredItems;
      $that.isotope('layout');
    },
    setInitialPosition: function ($that) {
      var self = this;
      var $gridItem = $(self.selector.item, $that);

      for (var i = 0; i <= $gridItem.length; i++) {
        $($gridItem[i]).attr('data-sort-initial-position', i + 1);
        if ($that.data('isotope')) {
          $that.isotope('updateSortData', $gridItem[i]);
        }
      }
    },
    attached: false
  };
})(jQuery);
